import { useLoaderData } from "react-router-dom"
import Header from "../Header"
import Game from "../Game"
import { Toaster } from "../ui/toaster"
import PuzzleDataProvider from "../../providers/PuzzleDataProvider"
import GameStatusProvider from "../../providers/GameStatusProvider"

function Home() {
  const game = useLoaderData()
  return (
    <PuzzleDataProvider game={game}>
      <GameStatusProvider>
        <div className="wrapper">
          <Toaster />
          <Header />
          <Game />
        </div>
      </GameStatusProvider>
    </PuzzleDataProvider>
  )
}
export default Home