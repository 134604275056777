import React from "react";
import BaseModal from "../BaseModal";
import { SolvedWordRow } from "../../GameGrid";
import ShareScoreButton from "../../ShareScoreButton";
import { PuzzleDataContext } from "../../../providers/PuzzleDataProvider";

function GameLostModal({ open }) {
  const { gameData } = React.useContext(PuzzleDataContext);

  return (
    <BaseModal
      title="நீங்கள் வெற்றி பெறவில்லை."
      initiallyOpen={open}
      footerElements={<ShareScoreButton />}
      showActionButton={false}
    >
      <div className="grid gap-y-2">
        <p className="text-lg font-[500] text-center">
          அடுத்த முயற்சியில் வெற்றி பெற வாழ்த்துகள். சரியான விடைகள் இதோ. 
        </p>
        {Object.keys(gameData[0]).length > 0 && gameData.map((obj) => (
          <SolvedWordRow key={obj.category} {...obj} />
        ))}
      </div>
    </BaseModal>
  );
}

export default GameLostModal;
