import { cva } from "class-variance-authority"
import { cn } from "../../lib/utils"

const formLabelVariants = cva("block text-black-500 font-bold md:text-right mb-1 md:mb-0 pr-4", {
  variants: {
    variant: {
      "level-1": "bg-level1",
      "level-2": "bg-level2",
      "level-3": "bg-level3",
      "level-4": "bg-level4"
    }
  }
})
export function FormLabel({variant, text, forInputName}) {
  return (
    <div className="md:w-1/3 mr-1">
      <label className={cn(formLabelVariants({variant}))} htmlFor={forInputName}>
        {text}
      </label>
    </div>
  )
}

export function FormInput({width, name, changeHandler}) {
  if (!width) {width = "md:w-2/3"}
  return (
    <div className={width}>
      <input required className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-slate-500" name={name} type="text" onChange={changeHandler} />
    </div>
  )
}

export function PuzzleCategory({categoryItem, categoryIndex, changeHandler}) {

  return (
    <div>
      <div className="md:flex md:items-center mb-6">
        <FormLabel variant={`level-${categoryIndex + 1}`} text={`வகை ${categoryIndex + 1}`} htmlForName="category" />
        <FormInput name="category" changeHandler={(event) => {changeHandler(event,categoryIndex)}} />
      </div>
      <div className="md:flex md:items-center mb-6">
        <FormLabel variant={`level-${categoryIndex + 1}`} text="சொற்கள்" htmlForName="word" />
        <div className="md:w-2/3">
          {categoryItem.words.map((wordItem, wordIndex) => (
            <input key={`${categoryIndex}_${wordIndex}`} className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full mb-1 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-slate-500" name="word" type="text" onChange={(event) => {changeHandler(event,categoryIndex, wordIndex)}} />
          ))}
        </div>
      </div>
    </div>
  )
}