import React from "react"
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom"
import Home from "../Home"
import PuzzleForm from "../PuzzleForm"

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
      loader: async ({ params }) => {
        return fetch('/api/D07A2B7303')
      }
    },
    {
      path: "/:ctID",
      element: <Home />,
      loader: async ({ params }) => {
        return fetch(`/api/${params.ctID}`)
      }
    },
    {
      path: "/build",
      element: <PuzzleForm />,
    },
  ])
  return (
    <RouterProvider router={router} />
  )
}

export default App
