import React from "react";
import { MAX_MISTAKES } from "../../../lib/constants";
import { Info } from "lucide-react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../ui/tabs";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../ui/accordion";
import BaseModal from "../BaseModal";

function InfoModal() {
  return (
    <BaseModal
      title=""
      trigger={<Info className="mr-4" />}
      initiallyOpen={false}
      actionButtonText="புரிந்தது!"
    >
      <Tabs defaultValue="how-to-play">
        <TabsList className="grid w-full grid-cols-1">
          <TabsTrigger value="how-to-play">விதிமுறை</TabsTrigger>
        </TabsList>
        <TabsContent value="how-to-play">
          {" "}
          <Accordion type="single" collapsible className="w-full">
            <AccordionItem value="item-1">
              <AccordionTrigger>குறிக்கோள் என்ன?</AccordionTrigger>
              <AccordionContent>
                சொற்களை வகைப்படுத்துதல். ஒரே வகையான சொற்களைக் கண்டுபிடித்தல்.
              </AccordionContent>
            </AccordionItem>
            <AccordionItem value="item-2">
              <AccordionTrigger>விளையாடுவது எப்படி?</AccordionTrigger>
              <AccordionContent>
                ஒவ்வொரு சொல்லாகத் தேர்வு செய்து, ஒரே வகையான நான்கு சொற்கள் சேர்ந்ததும், 
                'சரி பார்' என்ற பொத்தானைத் தட்டினால், உங்கள் ஊகம் சரியா என்று தெரியும். 
                சரியாக இருந்தால், அடுத்த நான்கு சொற்களைத் தேர்வு செய்யவும். தவறாக இருந்தால், 
                'அழித்திடு' என்ற பொத்தானைத் தட்டினால், தேர்வு செய்யப்பட்ட சொற்கள் முந்தைய நிலையை 
                அடையும். தேர்வு செய்யப்பட்ட எந்தச் சொல்லையும் மறுபடி தட்டினால் அது தேர்வு செய்யப்படாத
                நிலையை அடையும்.
              </AccordionContent>
            </AccordionItem>
            <AccordionItem value="item-3">
              <AccordionTrigger>எத்தனை முறை தவறாக ஊகிக்கலாம்?</AccordionTrigger>
              <AccordionContent>
                {`${MAX_MISTAKES} முறை தவறாக ஊகித்தால் விளையாட்டு முடிந்து விடும்.`}
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </TabsContent>
      </Tabs>
    </BaseModal>
  );
}

export default InfoModal;
