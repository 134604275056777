import React from "react";
import BaseModal from "../BaseModal";
import { SolvedWordRow } from "../../GameGrid";
function PuzzlePreviewModal({ puzzleData }) {

  return (
    <BaseModal
      title=""
      trigger={
      <button type="button" className="shadow bg-slate-500 hover:bg-slate-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded">
        சரி பார்
      </button>
      }
      initiallyOpen={false}
      showActionButton={true}
      actionButtonText="மூடு"
    >
      <div className="grid gap-y-2">
        {Object.keys(puzzleData.data).length > 0 && puzzleData.data.map((obj,i) => (
          <SolvedWordRow key={obj.category || i} {...obj} />
        ))}
      </div>
    </BaseModal>
  );
}

export default PuzzlePreviewModal;
