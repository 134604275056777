import { useState } from "react"
import Header from "../Header"
import { FormLabel, FormInput, PuzzleCategory } from "../ui/form"
import { puzzleCreate } from "../../lib/puzzle-create"
import PuzzlePreviewModal from "../modals/PuzzlePreviewModal/"
function PuzzleForm() {
  const [puzzleData, setPuzzleData] = useState({
    ctName: '',
    authorEmail: '',
    data: [
      {
        category: '',
        words: ['','','',''],
        difficulty: 1
      },
      {
        category: '',
        words: ['','','',''],
        difficulty: 2
      },
      {
        category: '',
        words: ['','','',''],
        difficulty: 3
      },
      {
        category: '',
        words: ['','','',''],
        difficulty: 4
      }
    ]
  });
  const [createdPuzzle, setCreatedPuzzle] = useState({
    puzzleCreated: false,
    ctID: ''
  })
  const handleChange = (event,dataIndex, wordIndex) => {
    const { name, value } = event.target;
    if (dataIndex === undefined && wordIndex === undefined) {
      setPuzzleData((prevFormData) => ({ ...prevFormData, [name]: value }))
    } else {
      if (wordIndex === undefined) {
        const newData = puzzleData.data.map((data, i) => {
          if (i === dataIndex) {
            return { ...data, category: value }
          } else {
            return data
          }
        })
        setPuzzleData((prevFormData) => ({ ...prevFormData, data: newData}))
      } else {
        const newWords = puzzleData.data[dataIndex].words.map((word, i) => {
          if (i === wordIndex) {
            return value
          } else {
            return word
          }
        })
        const newData = puzzleData.data.map((data, i) => {
          if (i === dataIndex) {
            return {...data, words: newWords}
          } else {
            return data
          }
        })
        setPuzzleData((prevFormData) => ({ ...prevFormData, data: newData}))
      }
    }
    
  }
  const handleSubmit = async (event) => {
    event.preventDefault();
    const response = await puzzleCreate(puzzleData)
    // show confirmation or just redirect to the new puzzle url
    if (response.ctID) {
      setCreatedPuzzle({
        puzzleCreated: true,
        ctID: response.ctID
      })
    }
  }
  return (
    <div className="form-wrapper">
      <Header />
      { !createdPuzzle.puzzleCreated && (
        <form className="w-full max-w-sm" onSubmit={handleSubmit}>
          <div className="md:flex md:items-center mb-6">
            <FormLabel text="புதிரின் பெயர்" htmlForName="ctName" />
            <FormInput name="ctName" changeHandler={handleChange} />
          </div>
          <div className="md:flex md:items-center mb-6">
            <FormLabel text="ஆசிரியர் மின்னஞ்சல்" htmlForName="authorEmail" />
            <FormInput name="authorEmail" changeHandler={handleChange} />
          </div>
          {puzzleData.data.map((item, index) => (<PuzzleCategory key={index} categoryItem={item} categoryIndex={index} changeHandler={handleChange} />)
          )}
          <div className="md:flex md:items-center">
            <div className="md:w-1/3"></div>
            <div className="md:w-1/3">
              <button 
                className="shadow bg-slate-500 hover:bg-slate-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded" 
                type="submit"
              >
                உருவாக்கு
              </button>
            </div>
            <div className="md:w-1/3">
              <PuzzlePreviewModal puzzleData={puzzleData} />
            </div>
          </div>
        </form>
      )}
      { createdPuzzle.puzzleCreated && (
        <div>
          புதிர் தயார்!<a href={`/${createdPuzzle.ctID}`} target='_blank' class="text-blue-600 visited:text-purple-600">இங்கே</a> சொடுக்கவும்.
        </div>
      )}
    </div>
  )
}
export default PuzzleForm